<template>
  <v-form @submit.prevent="submitResponse">
    <p>
      How many grams of magnesium oxide (<chemical-latex content="MgO" />) will be produced when
      <number-value :value="moles" /> moles of magnesium reacts with excess oxygen gas?
    </p>
    <calculation-input v-model="inputs.input1" append-text="grams" />
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import NumberValue from '@/tasks/components/NumberValue.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'CalculateGramsProduced',
  components: {ChemicalLatex, CalculationInput, NumberValue},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    moles() {
      return this.taskState.getValueBySymbol('moles').content;
    },
  },
};
</script>

<style scoped></style>
