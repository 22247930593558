import {computed, inject, InjectionKey, provide, Ref, ref} from '@vue/composition-api';

export const HIGHLIGHT_COLOR = '#cfff97';

const SHOW_RANDOM_VARIABLES = Symbol('showRandomVariables') as InjectionKey<Ref<boolean>>;

export function provideRandomNumberBackground(show?: Ref<boolean>) {
  const showRandomVariables = show ?? ref(false);

  provide(SHOW_RANDOM_VARIABLES, showRandomVariables);

  return {showRandomVariables};
}

export function useRandomNumberBackgroundStyle() {
  const showRandomVariables = inject(SHOW_RANDOM_VARIABLES, ref(false));

  const style = computed(() =>
    showRandomVariables.value ? `background-color: ${HIGHLIGHT_COLOR}` : undefined
  );

  return {style};
}
