<template>
  <latex-number :number="computedValue" :style="style" />
</template>

<script>
import NV from '../../task-values/models/legacy/LegacyNumberValue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';
import {useRandomNumberBackgroundStyle} from '@/tasks/components/composables/useRandomNumberBackgroundStyle';

export default {
  name: 'NumberValue',
  components: {LatexNumber},
  props: {
    value: {
      type: NV,
      required: true,
    },
    unit: {
      type: String,
      default: null,
    },
    prependText: {
      type: String,
      default: null,
    },
  },
  setup() {
    return useRandomNumberBackgroundStyle();
  },
  computed: {
    computedValue() {
      let result = '';

      if (this.prependText) {
        result += `${this.prependText}\\ `;
      }
      if (this.value.isScientificNotation) {
        const number = this.value.scientificNotationNumber;
        const exponent = this.value.scientificNotationExponent;
        result += number;
        if (exponent !== 0) {
          result += ` \\times 10^{${exponent}}`;
        }
      } else {
        result += this.value.decimalNumber;
      }
      if (this.unit) {
        result += ` \\ ${this.unit}`;
      }
      return result;
    },
  },
};
</script>
