<template>
  <stemble-latex :content="latexContent" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'LatexNumber',
  components: {StembleLatex},
  props: {
    number: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      default: null,
    },
  },
  computed: {
    latexContent() {
      let result = this.number;
      if (this.unit) {
        result += ` \\ ${this.unit}`;
      }
      return `$${result}$`;
    },
  },
};
</script>
